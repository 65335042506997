import React from "react"
import * as cn from "classnames"
import Layout from "../components/layout"
import Img from "gatsby-image"
import Posts from "../components/layout/Posts"
import {graphql} from "gatsby"
import {HelmetDatoCms} from "gatsby-source-datocms"
import {SRLWrapper} from "simple-react-lightbox";
import Payu from "../components/layout/Payu";

export default class Page extends React.Component {
    render() {
        const p = this.props, { } = p
        const page = p.data.page

        const options = {
            thumbnails: {
                showThumbnails: false
            },
            settings: {
                overlayColor: "rgba(0,0,0,0.83)",
                autoplaySpeed: 1500,
                transitionSpeed: 900,
            },
            buttons: {
                backgroundColor: "transparent",
                iconColor: "#FEA21B",
            }
        }

        return (
            <Layout className="page">
                <HelmetDatoCms seo={page.seoMetaTags} />
                {page.mainImage &&
                    <div className="main-image">
                        <Img fluid={page.mainImage.fluid}/>
                    </div>
                }
                <div className={cn("content", !page.mainImage && "top")}>
                    <div className="page__content">
                        {page.contentWithSeparatorNode.childMarkdownRemark.html &&
                            <div className="info">
                               <pre className={cn(page.textJustify && "centered", page.textCentered && "justify")} dangerouslySetInnerHTML={{
                                   __html: page.contentWithSeparatorNode.childMarkdownRemark.html,
                               }}/>
                               <hr />
                            </div>
                        }
                        {page.contentNode &&
                            <pre className={cn(page.textJustify && "centered", page.textCentered && "justify")} dangerouslySetInnerHTML={{
                                __html: page.contentNode.childMarkdownRemark.html,
                            }}/>
                        }
                    </div>
                    {page.gallery && page.gallery.length > 0 &&
                    <div>
                        <div className="gallery">
                            <SRLWrapper options={options}>
                                {page.gallery.map(({ fluid: image }) => (
                                    <a href={image.src} data-attribute="SRL">
                                        <img src={`${image.srcSet}&ar=1:1&fit=crop`} alt=""/>
                                    </a>
                                ))}
                            </SRLWrapper>
                        </div>
                    </div>
                    }
                    {page.showPayu &&
                        <Payu />
                    }
                    {page.showArticles &&
                        <Posts />
                    }
                </div>
            </Layout>
        )
    }
}

export const query = graphql`
  query PageQuery($slug: String!) {
    page: datoCmsPage(slug: { eq: $slug }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      slug
      header
      textJustify
      textCentered
      contentNode {
        childMarkdownRemark{
            html
        }
      }
      contentWithSeparatorNode {
        childMarkdownRemark{
          html
        }
      }
      showArticles
      showPayu
      mainImage {
        fluid(imgixParams: { auto: "compress" }) {
          ...GatsbyDatoCmsSizes
        }
      }
      gallery {
        fluid(imgixParams: { auto: "compress" }) {
          ...GatsbyDatoCmsSizes
        }
      }
    }
  }
`